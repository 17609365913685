<template>
  <div style="height: 65vh;" >
    <v-layout  row wrap fill-height justify-center align-center>
      <v-flex text-center>
        <p class="display-3 font-weight-bold error--text">Oops!</p>
        <p class="title">This page doesn't exist.</p>
        <span>
          Please <a class="font-weight-medium" @click="goBack">return</a> to the previous page<span v-if="!$store.getters['user/user'].user.fullName">, or visit the <a class="font-weight-medium" @click="goHome">home page.</a></span>
        </span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  methods: {
    goBack () {
      window.history.back()
    },
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
